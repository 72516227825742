// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const initialUser = () => {
    const item = window.localStorage.getItem("userData");

    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

const avatarImgUrl = () => {
    const item = window.localStorage.getItem("userData");

    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
    name: "authentication",
    initialState: {
        userData: initialUser(),
    },
    reducers: {
        handleLogin: (state, action) => {
            action.payload.ability = [
                {
                    action: "manage",
                    subject: "all",
                },
            ];
            state.userData = action.payload;
            localStorage.setItem("userData", JSON.stringify(action.payload));
        },

        handleLogout: (state) => {
            state.userData = {};
            state.userId = {};
            state.customerId = {};
            localStorage.clear();
        },
    },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
