import logo from "@src/assets/images/logo/logoom_logo.png";
import "../../../assets/css/pagination.css";
import "../../../assets/scss/loading.scss";

const SpinnerComponent = ({ spiner, spinnerHeight }) => {

    return (
        <div className="spinnerContent">
            <div className="spinners">
                <span className="ball-1"></span>
                <span className="ball-2"></span>
                <span className="ball-3"></span>
                <span className="ball-4"></span>
                <span className="ball-5"></span>
                <span className="ball-6"></span>
                <span className="ball-7"></span>
                <span className="ball-8"></span>
            </div>
        </div>
        // <div
        //     className={
        //         spiner
        //             ? "spinerTop fallback-spinner app-loader"
        //             : "fallback-spinner app-loader"
        //     }
        //     style={{ height: spinnerHeight ? "55vh" : "" }}
        // >
        //     <img className="fallback-logo" src={logo} alt="logo" />
        //     <div className="loading">
        //         <div className="effect-1 effects"></div>
        //         <div className="effect-2 effects"></div>
        //         <div className="effect-3 effects"></div>
        //     </div>
        // </div>
    );
};

export default SpinnerComponent;
